<template>
  <div class="box">
    <div class="infos">
      <div class="titles">
        <div class="left"></div>
        <div class="right">
          <div class="pick">
            <el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
          </div>
          <div class="search">
            <i class="el-icon-search"></i>
            <input class="el-input__inner" type="text" autocomplete="off" placeholder="输入账号/手机号搜索" />
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <el-table :data="list" height="calc(100vh - 200px)" @sort-change="changeTableSort">
        <el-table-column prop="name" label="项目名称" width="290">
          <div slot-scope="scope" class="project">
            <div class="imgs">
              <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1674007189754ce.png" alt="" />
              <img src="https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1674007183902ce.png" alt="" />
            </div>
            <div class="text">
              <div class="text-hide">{{ scope.row.name.name }}</div>
              <div class="order-num">{{ '订单编号：' + scope.row.name.num }}</div>
            </div>
          </div>
        </el-table-column>
        <el-table-column label="开通平台" align="center" width="240px">
          <div slot-scope="scope" class="images">
            <img style="width: 20px; height: 20px" v-for="item in scope.row.platform" :src="item" alt="" />
          </div>
        </el-table-column>
        <el-table-column prop="programme" label="解决方案" align="center" width="160">
          <div slot-scope="scope" class="proText">
            <span>本地商超解决方案</span>
          </div>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" prop="ability" label="增值功能" align="center">
          <div slot-scope="scope" class="abili">
            <el-popover placement="right" :disabled="scope.row.ability.length < 1 ? true : false" trigger="hover">
              <div class="list">
                <span v-for="item in scope.row.ability">{{ item.text }}</span>
              </div>
              <span slot="reference">{{ '开通 ' + scope.row.ability.length + '个增值功能' }}</span>
            </el-popover>
          </div>
        </el-table-column>
        <el-table-column prop="duration" label="开通时长" align="center"></el-table-column>
        <el-table-column prop="agent" label="所属代理商" align="center">
          <el-popover placement="right" slot-scope="scope" trigger="hover">
            <el-table :data="gridData">
              <el-table-column width="150" property="date" label="日期"></el-table-column>
              <el-table-column width="100" property="name" label="姓名"></el-table-column>
              <el-table-column width="300" property="address" label="地址"></el-table-column>
            </el-table>
            <span slot="reference">{{ scope.row.agent }}</span>
          </el-popover>
        </el-table-column>
        <el-table-column prop="price" label="总计扣除" align="center"></el-table-column>
        <el-table-column prop="people" label="操作人" align="center"></el-table-column>
        <el-table-column prop="time" label="生效时间" align="center">
          <el-tooltip slot-scope="scope" :content="scope.row.time" placement="top">
            <span class="number">{{ scope.row.time }}</span>
          </el-tooltip>
        </el-table-column>
      </el-table>
      <div class="bottom">
        <el-pagination :page-sizes="[10, 20, 30, 40]" :page-size="10" :pager-count="4" layout="total, sizes, prev, pager, next, jumper" :total="400"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/utils/cloud'

export default {
  components: {},
  data() {
    return {
      // gridData: [
      //   {
      //     date: '2016-05-02',
      //     name: '王小虎',
      //     address: '上海市普陀区金沙江路 1518 弄'
      //   },
      //   {
      //     date: '2016-05-04',
      //     name: '王小虎',
      //     address: '上海市普陀区金沙江路 1518 弄'
      //   },
      //   {
      //     date: '2016-05-01',
      //     name: '王小虎',
      //     address: '上海市普陀区金沙江路 1518 弄'
      //   },
      //   {
      //     date: '2016-05-03',
      //     name: '王小虎',
      //     address: '上海市普陀区金沙江路 1518 弄'
      //   }
      // ],
      // list: [
      //   {
      //     name: {
      //       name: '123',
      //       num: '20230117170361987914'
      //     },
      //     platform: [
      //       'https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673922578578ce.png',
      //       'https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673922583841ce.png',
      //       'https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673922590057ce.png',
      //       'https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673922594944ce.png',
      //       'https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673922604087ce.png',
      //       'https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673922617053ce.png',
      //       'https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673922624858ce.png'
      //     ],
      //     ability: [
      //       {
      //         text: '限时秒杀（12个月）'
      //       },
      //       {
      //         text: '多人拼团（永久）'
      //       },
      //       {
      //         text: '预约预定（永久）'
      //       },
      //       {
      //         text: '全民砍价（永久）'
      //       },
      //       {
      //         text: '摇一摇抽奖（永久）'
      //       }
      //     ],
      //     duration: '未改变',
      //     agent: 'vx',
      //     price: '0.00',
      //     people: '第二世界',
      //     time: '2023-01-20'
      //   },
      //   {
      //     name: {
      //       name: 'lili',
      //       num: '20230117170361987914'
      //     },
      //     platform: [
      //       'https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673922578578ce.png',
      //       'https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673922583841ce.png',
      //       'https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673922590057ce.png',
      //       'https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673922594944ce.png',
      //       'https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673922604087ce.png',
      //       'https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673922617053ce.png',
      //       'https://pbw-uploadfile.oss-cn-beijing.aliyuncs.com/zhibai/1673922624858ce.png'
      //     ],
      //     ability: [],
      //     duration: '未改变',
      //     agent: 'vx',
      //     price: '0.00',
      //     people: '第二世界',
      //     time: '2023-01-20'
      //   }
      // ]
    }
  },
  created() {
    this.loadList()
  },
  methods: {
    async loadList() {}
  }
}
</script>

<style lang="scss" scoped>
::v-deep thead {
  th.el-table__cell {
    background: #ecf1f6;
  }
  color: #20303f !important;
}
::v-deep tbody {
  tr:nth-child(2n) {
    background: #fafbfc;
  }
}
.el-popover {
  .list {
    display: flex;
    flex-direction: column;
    span {
      color: #586879;
      line-height: 1.4;
      font-size: 14px;
      margin: 6px 0;
    }
  }
}
.box {
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: auto;
  background-color: #f4f6f8;
  .infos {
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
    .titles {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .left {
      }
      .right {
        display: flex;
        align-items: center;
        .pick {
          width: 236px;
          height: 36px;
          border-radius: 4px;
          box-shadow: 0 0 23px 2px rgb(54 124 253 / 10%);
          margin-right: 10px;
          display: flex;
          .el-input__inner {
            width: 100%;
            height: 36px;
            color: #606266;
            border: none;
          }
          ::v-deep .el-date-editor .el-range-separator {
            width: 10%;
          }
          ::v-deep .el-range-input {
            flex: 1;
          }
          ::v-deep .el-date-editor .el-range__close-icon {
            display: none;
          }
        }
        .search {
          width: 200px;
          height: 36px;
          display: flex;
          flex-grow: 1;
          align-items: center;
          justify-content: center;
          padding: 1px 11px;
          border-radius: 4px;
          background: #fff;
          box-shadow: 0 0 23px 2px rgb(54 124 253 / 10%);
          i {
            display: inline-flex;
            white-space: nowrap;
            flex-shrink: 0;
            flex-wrap: nowrap;
            text-align: center;
            color: #a8abb2;
            transition: all 0.3;
            pointer-events: none;
            margin-right: 8px;
          }
          .el-input__inner {
            width: 100%;
            height: 36px;
            flex-grow: 1;
            color: #606266;
            padding: 0;
            border: none;
          }
        }
      }
    }
    .el-table {
      border-radius: 5px;
      box-shadow: 0 4px 14px 1px rgb(54 124 253 / 8%);
      .el-table__header-wrapper {
        border-radius: 5px 5px 0 0;
      }
      font-size: 14px;
      font-weight: 400;
      color: #6b7c8c;
      .project {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        font-weight: 400;
        color: #6b7c8c;
        .imgs {
          display: flex;
          position: relative;
          img {
            &:nth-child(1) {
              width: 28px;
              height: 15px;
              position: absolute;
              top: 0;
              left: 0;
            }
            &:nth-child(2) {
              width: 40px;
              height: 40px;
              border-radius: 5px;
            }
          }
        }
        .text {
          width: calc(100% - 40px);
          padding-left: 10px;
          .order-num {
            font-size: 13px;
          }
        }
      }
      .abili {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .proText {
        display: flex;
        width: max-content;
        padding: 5px 10px;
        font-size: 13px;
        line-height: 26px;
        color: #f56c6c;
        background: rgba(245, 108, 108, 0.1);
        border-radius: 13px;
        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .images {
        display: flex;
        align-items: center;
        justify-content: center;
        > img:not(:nth-child(1)) {
          margin-left: 10px;
        }
      }
      .number {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .bottom {
      width: 100%;
      display: flex;
      margin-top: 20px;
      justify-content: flex-end;
    }
  }
}
</style>
